@import '../theme-bootstrap';

.video-full-width,
.hero-banner,
.hero-large,
.hero-50-50 {
  position: relative;

  // Give videos a little bit of breathing room when
  // placed in the context of the SPP related item sets.
  // If we ever add default top/bottom spacing to related item
  // rows, then we should remove this.
  .spp__related-item & {
    margin: 20px auto;
  }
  &__close {
    text-align: right;
    .video-full-width--play-in-line &,
    .hero-banner--play-in-line &,
    .hero-large--play-in-line &,
    .hero-50-50--play-in-line & {
      display: none;
    }
  }
  .icon--close {
    visibility: hidden;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    color: $color-black;
    margin: 0 3px 5px 5px;
    @include breakpoint($medium-up) {
      font-size: 20px;
      margin: 0 0 15px 0;
    }
  }
  &.active {
    .icon--close {
      visibility: visible;
    }
  }
  &__container {
    position: relative;
  }
  &__reveal {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    .video-full-width.active &,
    .hero-banner.active &,
    .hero-large.active &,
    .hero-50-50.active & {
      display: block;
      opacity: 1;
      height: 100%;
      iframe {
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  video {
    display: block;
    height: 100%;
    width: 100%;
  }
  &--click-toggle-sound {
    video {
      cursor: pointer;
    }
  }
  &__toggle-sound-message {
    position: absolute;
    background: $color-mid-gray;
    color: $color-white;
    font-size: 14px;
    z-index: 2;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    padding: 20px 30px;
    text-align: center;
    pointer-events: none;
    transition: opacity 1.5s;
    @include breakpoint($small-up) {
      font-size: 25px;
    }
  }

  // enable-toggle-sound-message is assigned in video_full_width_v1.js
  &.active.enable-toggle-sound-message &__toggle-sound-message {
    opacity: 1;
  }
  &__landing {
    position: relative;
    transition: opacity 0.3s;
    cursor: pointer;
    .video-full-width.active &,
    .hero-banner.active &,
    .hero-large.active &,
    .hero-50-50.active & {
      z-index: 0;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-white;
    font-size: 40px;
  }
  &__image {
    display: block;
    max-height: 480px;
    width: 100%;
    // since image is used to define dimensions. take off max-height
    // since image width will likely be wider than the 16:9 of video
    .video-full-width--play-in-line &,
    .hero-banner--play-in-line &,
    .hero-large--play-in-line &,
    .hero-50-50--play-in-line & {
      max-height: none;
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .hide-play-icon {
    visibility: hidden;
  }
  &--full-width,
  &--max-width-960,
  &--max-width-1070,
  &--max-width-1280 {
    margin: 0 auto;
    width: 100%;
  }
  &--full-width {
    max-width: 100%;
  }
  &--max-width-960 {
    max-width: 960px;
  }
  &--max-width-1070 {
    max-width: 1070px;
  }
  &--max-width-1280 {
    max-width: 1280px;
  }
}
